import config, { getCurrentTheme } from '../../containers/ThemeSwitcher/config'

const initState = {
  isActivated: false,
  changeThemes: getCurrentTheme('changeThemes', config.changeThemes.defaultTheme || 'themedefault'),
  topbarTheme: getCurrentTheme('topbarTheme', config.topbarTheme.defaultTheme || 'themedefault'),
  sidebarTheme: getCurrentTheme('sidebarTheme', config.sidebarTheme.defaultTheme || 'themedefault'),
  layoutTheme: getCurrentTheme('layoutTheme', config.layoutTheme.defaultTheme || 'themedefault')
}

export default function(state = initState, action) {
  switch (action.type) {
    default:
      return state
  }
}
