import {applyMiddleware, compose, createStore} from 'redux'
import {routerMiddleware} from 'connected-react-router'
import {createBrowserHistory} from 'history'
import createSagaMiddleware from 'redux-saga'

import rootSaga from './sagas'
import createRootReducer from './reducers'


const sagaMiddleware = createSagaMiddleware()


export const history = createBrowserHistory()

const middlewares = [sagaMiddleware, routerMiddleware(history)]

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
export const store = createStore(
	createRootReducer(history),
	{}, //preloadedState,
	composeEnhancers(
		applyMiddleware(...middlewares)
	)
)

sagaMiddleware.run(rootSaga)