import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'

import App from './app/reducer'
import Theme from './theme/reducer'
import Auth from './auth/reducer'
import Me from './me/reducer'

import Client from './client/reducer'
import Project from './project/reducer'
import Alert from './alert/reducer'

export default (history) => combineReducers({
	router: connectRouter(history),

	App,
	Theme,
	Auth,
	Me,

	Client,
	Project,
	Alert,
})