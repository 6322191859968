import axios from './axios'
import {query} from './apollo'

export const lost = function(login){
	const date = new Date()
	const url = document.location.origin + '/?' + date.getTime()

	return axios
		.post('/lost', {login, url })
		.then(res => res.data)
}

export const reset = function(token, password){
	return axios
		.post(`/reset/${token}`, {password})
		.then(res => res.data)
}

export const login = function(login, password){
	return axios
		.post('/login', {login, password})
		.then(res => res.data)
}

export const auth = function(token){
	//console.log('[API]', 'auth', token)

	return query(`
		query($auth: String!){
			getUserByAuth(auth: $auth){
	      _id auth login role firstName lastName
	    }
	  }`,
		{
			auth: token
		}
	)
	.then(res => res.data.getUserByAuth)
	.catch(err => console.log('🔥 GRAPHQL', err))
}

