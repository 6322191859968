import gql from 'graphql-tag'
import {ApolloClient} from 'apollo-client'
import {HttpLink} from 'apollo-link-http'
import {InMemoryCache} from 'apollo-cache-inmemory'

const client = new ApolloClient({
	cache: new InMemoryCache(),
	link: new HttpLink({
		uri: process.env.REACT_APP_API + '/graphql',
		headers: {
			Auth : localStorage.getItem('kapp_token') || ''
		}
	})
})

export const query = (query, variables={}) => {
	return client.query({
		fetchPolicy: 'network-only',
		variables,
		query: gql(query)
	})
}