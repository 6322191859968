import {all, takeEvery, put, fork} from 'redux-saga/effects'

import actions from './actions'

import * as api from '../../helpers/api-alert'


export function* search(){
	yield takeEvery(actions.TIMELINE_ALERTS, function* () {

		try{
			const res = yield api.timeline()

			if(res.error){
				yield put({type: actions.TIMELINE_ALERTS_ERROR})
			}else{
				yield put({type: actions.TIMELINE_ALERTS_SUCCESS, payload: res.projects})
			}

		} catch(err){
			console.log('🔥', err)
			yield put({type: actions.TIMELINE_ALERTS_ERROR})
		}

	})
}



export default function* rootSaga(){
	yield all([
		fork(search),
	])
}
