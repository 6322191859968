import actions from './actions'

const initState = {
	loaded: false,
	params: {
		limit: 999,
		skip: 0,
	},
	timeline: []
}

export default function projectReducer(state = initState, action) {

	const {payload} = action

	switch (action.type) {

		case actions.TIMELINE_ALERTS:
			return {
				...state,
				requesting: true
			}
		case actions.TIMELINE_ALERTS_SUCCESS:
			return {
				...state,
				requesting: false,
				timeline: payload
			}

		default:
			return state
	}
}