import {all} from 'redux-saga/effects'

import authSagas from './auth/saga'
import meSagas from './me/saga'

import clientSagas from './client/saga'
import projectSagas from './project/saga'
import alertSagas from './alert/saga'

export default function* rootSaga() {
	yield all([
		authSagas(),
		meSagas(),

		clientSagas(),
		projectSagas(),
		alertSagas(),
	])
}