import {all/*, takeEvery, select, put, fork*/} from 'redux-saga/effects'

//import actions from './actions'
//import * as api from '../../helpers/api-users'
//import * as mediaApi from '../../helpers/api-media'

//import {openErrorNotification, openSuccessNotification} from '../../helpers/notification'

/*export function* saveMyProfile(){
	yield takeEvery('SAVE_MYPROFILE', function* (){
		//console.log('[SAGA]', 'saveProfile()')

		let profile = yield select(state => state.MyProfile)

		try{
			const res = yield api.saveUser(profile)

			if(res.error){
				openErrorNotification(res.error)
				yield put({type: actions.SAVE_MYPROFILE_ERROR})
			}else{
				openSuccessNotification('Profil mis à jour')
				yield put({type: actions.SAVE_MYPROFILE_SUCCESS, payload: res})
			}

		} catch(err){
			console.log('🔥', err)
			yield put({type: actions.SAVE_MYPROFILE_ERROR})
			openErrorNotification('@#')
		}

	})
}

export function* uploadMyProfileAvatar(){
	yield takeEvery('UPLOAD_MYPROFILE_AVATAR', function* ({payload}){
		const {file, cb} = payload
		//console.log('[SAGA]', 'uploadAvatar()', file)

		try{
			const res = yield mediaApi.upload(file)
			if(cb) cb()

			if(res.error){
				yield put({type: actions.UPLOAD_MYPROFILE_AVATAR_ERROR})
			}else{
				yield put({type: actions.UPLOAD_MYPROFILE_AVATAR_SUCCESS, payload: res})
			}

		} catch(err){
			console.log('🔥', err)
			yield put({type: actions.UPLOAD_MYPROFILE_AVATAR_ERROR})
		}

	})
}*/

export default function* rootSaga(){
	yield all([
		//fork(saveMyProfile),
		//fork(uploadMyProfileAvatar)
	])
}