const actions = {
	TIMELINE_ALERTS: 'TIMELINE_ALERTS',
	TIMELINE_ALERTS_SUCCESS: 'TIMELINE_ALERTS_SUCCESS',
	TIMELINE_ALERTS_ERROR: 'TIMELINE_ALERTS_ERROR',

	// -- Multiple

	timeline: () => ({
		type: actions.TIMELINE_ALERTS
	})

}

export default actions