export default {
  apiUrl: 'http://yoursite.com/api/'
}

const siteConfig = {
  siteName: 'Kappuccino',
  siteIcon: 'left',
  footerText: 'Kappuccino ©2019 Created by a Human'
}

const themeConfig = {
  topbar: 'themedefault',
  sidebar: 'themedefault',
  layout: 'themedefault',
  theme: 'themedefault'
}

const language = 'english'

export {
  siteConfig,
  themeConfig,
  language
}