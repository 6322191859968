import React, {Component} from 'react'
import {connect} from 'react-redux'
import {Layout} from 'antd'
import {Debounce} from 'react-throttle'
import WindowResizeListener from 'react-window-size-listener'
import {ThemeProvider} from 'styled-components'

import authAction from '../../redux/auth/actions'
import appActions from '../../redux/app/actions'
import Sidebar from '../Sidebar/Sidebar'
import Topbar from '../Topbar/Topbar'
import AppRouter from './AppRouter'
import AppHolder from './commonStyle'

import {siteConfig} from '../../settings'
import themes from '../../settings/themes'
import './global.css'

const { Content, Footer } = Layout

export class App extends Component {
  render() {
    const { url } = this.props.match
    const { selectedTheme, height } = this.props
    const appHeight = window.innerHeight

    return (
      <ThemeProvider theme={themes[selectedTheme]}>
        <AppHolder>
          <Layout style={{ height: appHeight }}>

            <Debounce time="1000" handler="onResize">
              <WindowResizeListener
                onResize={winSize => this.props.toggleAll(winSize.windowWidth, winSize.windowHeight)}
              />
            </Debounce>

            <Topbar url={url} />

            <Layout style={{ flexDirection: 'row', overflowX: 'hidden' }}>
              <Sidebar url={url} />
              <Layout className="isoContentMainLayout" style={{height: height}}>
                <Content
                  className="isomorphicContent"
                  style={{
                    padding: '50px 0 0',
                    flexShrink: '0',
                    background: '#f1f3f6',
                    position: 'relative'
                  }}
                >
                  <AppRouter url={url} />
                </Content>

                <Footer style={{background: '#ffffff',textAlign: 'center',borderTop: '1px solid #ededed'}}>
                  {siteConfig.footerText}
                </Footer>
              </Layout>
            </Layout>

          </Layout>
        </AppHolder>
      </ThemeProvider>
    )
  }
}

export default connect(

  // mapStateToProp
  state => ({
    auth: state.Auth,
    selectedTheme: state.Theme.changeThemes.themeName,
    height: state.App.get('height')
  }),

  // mapDispatchToProp
  {
    logout: authAction.logout,
    toggleAll: appActions.toggleAll
  }
)(App)