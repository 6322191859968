import crypto from '../../helpers/crypto'
import actions from './actions'

const hashed = crypto.decrypt(localStorage.getItem('kappuccino')) || ''
let initState = {}

try{
	initState = JSON.parse(hashed)
} catch (err) {
	//
}

export default function profileReducer(state = initState, action) {

	const payload = action.payload

	switch (action.type) {

		case actions.MYPROFILE_LOADED:
			return payload

		case action.MYPROFILE_UNLOAD:
			return {}

		// A la sauvegarde du profile, faire sauter le mot de passe pour vider MYPROFILE_LOADED le champs
		case actions.SAVE_MYPROFILE_SUCCESS:
			return {
				...state,
				password: null
			}

		case actions.CHANGE_MYPROFILE:
			return {
				...state,
				...payload.field
			}

		case actions.UPLOAD_MYPROFILE_AVATAR_SUCCESS:
			return {
				...state,
				_avatar: payload._id,
				avatar: payload
			}

		default:
			return state
	}
}
