import React, {Component} from 'react'
import {connect} from 'react-redux'

import Popover from '../../components/uielements/popover'
import authAction from '../../redux/auth/actions'
import TopbarDropdownWrapper from './topbarDropdown.style'
import Avatar from '../../components/avatar'

class TopbarUser extends Component {

	state = {
		visible: false
	}

	hide = () => {
		this.setState({visible: false})
	}

	handleVisibleChange = () => {
		this.setState({visible: !this.state.visible})
	}

	render() {
		const content = (
			<TopbarDropdownWrapper className="isoUserDropdown">
				{/*<a className="isoDropdownLink" href="#">settings</a>
				<a className="isoDropdownLink" href="#">feedback</a>
				<a className="isoDropdownLink" href="#">help</a>*/}
				<a className="isoDropdownLink" onClick={this.props.logout} href="/">Se déconnecter</a>
			</TopbarDropdownWrapper>
		)

		return (
			<Popover
				content={content}
				trigger="click"
				visible={this.state.visible}
				onVisibleChange={this.handleVisibleChange}
				arrowPointAtCenter={true}
				placement="bottomLeft"
			>
				<div className="isoImgWrapper">
					<Avatar profile={this.props.profile} />
					<span className="userActivity online"/>
				</div>
			</Popover>
		);
	}
}

export default connect(
	// mapStateToProps
	state => ({
		profile: state.Me
	}),

	// mapDispatchToProps
	{
		logout: authAction.logout
	}
)(TopbarUser)