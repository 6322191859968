import React, {Component} from "react"
import {connect} from "react-redux"
import {Layout} from "antd"

import appActions from "../../redux/app/actions"
import TopbarUser from "./topbarUser"
import TopbarWrapper from "./topbar.style"

class Topbar extends Component {

	render() {
		const {toggleCollapsed, theme} = this.props

		const collapsed = this.props.collapsed && !this.props.openDrawer

		const styling = {
			background: theme.backgroundColor,
			position: "fixed",
			width: "100%",
			height: 70
		}

		return (
			<TopbarWrapper>
				<Layout.Header style={styling} className={collapsed ? "isomorphicTopbar collapsed" : "isomorphicTopbar"}>

					<div className="isoLeft">
						<button className={collapsed ? "triggerBtn menuCollapsed" : "triggerBtn menuOpen"}
						        style={{color: theme.textColor}}
						        onClick={toggleCollapsed} />
					</div>

					<ul className="isoRight">
						<li onClick={() => this.setState({selectedItem: "user"})} className="isoUser">
							<TopbarUser/>
						</li>
					</ul>

				</Layout.Header>
			</TopbarWrapper>
		)
	}
}

export default connect(
	// mapStateToProps
	state => ({
		collapsed: state.App.get('collapsed'),
		openDrawer: state.App.get('openDrawer'),
		theme: state.Theme.topbarTheme
	}),

	// mapDispatchToProps
	{
		toggleCollapsed: appActions.toggleCollapsed
	}
)(Topbar)