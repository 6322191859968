import React from 'react'
import ReactDOM from 'react-dom'
import 'antd/dist/antd.css'

import './styles/style.css'

import DashApp from './dashApp'

ReactDOM.render(
	<DashApp/>,
	document.getElementById('root')
)

// Hot Module Replacement API
if (module.hot) {
	module.hot.accept('./dashApp.js', () => {
		const NextApp = require('./dashApp').default
		ReactDOM.render(<NextApp/>, document.getElementById('root'))
	})
}
