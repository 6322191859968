import actions from './actions'

const initState = {
	loaded: false,
	single: {},
	created: '',
	total: 0,
	params: {
		limit: 999,
		skip: 0,
	},
	data: []
}

export default function clientReducer(state = initState, action) {

	const {payload} = action

	switch (action.type) {

		case actions.LOAD_CLIENT_SUCCESS:
			return {
				...state,
				single: payload
			}

		case actions.CHANGE_CLIENT:
			return {
				...state,
				single: {
					...state.single,
					...payload
				}
			}

		case actions.RESET_CLIENT:
			return {
				...state,
				single: {}
			}

		case actions.SAVE_CLIENT_SUCCESS:
			if(payload.isNew){
				console.log('actions.SAVE_CLIENT_SUCCESS', 'isNew', payload)

				return {
					...state,
					created: payload._id,
					data: [...state.data, payload]
				}
			}

			return state


		// MULTIPLE

		case actions.REMOVE_CLIENT:
			return {
				...state,
				data: state.data.filter(d => d._id !== payload)
			}

		case actions.SEARCH_CLIENTS_PARAMS:
			return {
				...state,
				params: {
					...state.params,
					...payload
				}
			}

		case actions.SEARCH_CLIENTS_SUCCESS:
			return {
				...state,
				total: payload.total,
				data: payload.data
			}

		default:
			return state
	}
}
