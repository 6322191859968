import React, {Component} from 'react'
import {connect} from 'react-redux'
import clone from 'clone'
import {Link} from 'react-router-dom'
import {Layout, Icon} from 'antd'

//import options from './options'
import Scrollbars from '../../components/utility/customScrollBar.js'
import Menu from '../../components/uielements/menu'
import SidebarWrapper from './sidebar.style'
import Logo from '../../components/utility/logo'

import actions from '../../redux/app/actions'

const stripTrailingSlash = str => {
  if (str.substr(-1) === "/") return str.substr(0, str.length - 1);
  return str
}

class Sidebar extends Component {

  handleClick = e => {
    this.props.changeCurrent([e.key])

    if (this.props.view === "MobileView") {
      setTimeout(() => {
        this.props.toggleCollapsed()
        this.props.toggleOpenDrawer()
      }, 100)
    }
  }

  onOpenChange = newOpenKeys => {
    const {openKeys, changeOpenKeys} = this.props

    const latestOpenKey = newOpenKeys.find(key => !(openKeys.indexOf(key) > -1))
    const latestCloseKey = openKeys.find(key => !(newOpenKeys.indexOf(key) > -1))

    let nextOpenKeys = []

    if (latestOpenKey) nextOpenKeys = this.getAncestorKeys(latestOpenKey).concat(latestOpenKey)
    if (latestCloseKey) nextOpenKeys = this.getAncestorKeys(latestCloseKey)

    changeOpenKeys(nextOpenKeys)
  }

  getAncestorKeys = key => {
    const map = {
      //sub3: ["sub1"]
    }

    return map[key] || []
  }

  getMenuItem = ({ singleOption, submenuStyle, submenuColor }) => {
    const { key, label, leftIcon, children } = singleOption
    const url = stripTrailingSlash(this.props.url)

    if (children) {
      return (
        <Menu.SubMenu
          key={key}
          title={
            <span className="isoMenuHolder" style={submenuColor}>
              <i className={leftIcon} />
              <span className="nav-text">{label}</span>
            </span>
          }
        >
          {children.map(child => {
            const linkTo = child.withoutDashboard
              ? `/${child.key}`
              : `${url}/${child.key}`;
            return (
              <Menu.Item style={submenuStyle} key={child.key}>
                <Link style={submenuColor} to={linkTo}>{child.label}</Link>
              </Menu.Item>
            )
          })}
        </Menu.SubMenu>
      )
    }

    return (
      <Menu.Item key={key}>
        <Link to={`${url}/${key}`}>
          <span className="isoMenuHolder" style={submenuColor}>
            <i className={leftIcon} />
            <span className="nav-text">{label}</span>
          </span>
        </Link>
      </Menu.Item>
    )
  }

  render() {
    const {openKeys, openDrawer, toggleOpenDrawer, customizedTheme, height, current} = this.props
    const collapsed = clone(this.props.collapsed) && !clone(openDrawer)
    const mode = collapsed === true ? "vertical" : "inline"

    const onMouseEnter = () => {
      if (openDrawer === false) toggleOpenDrawer()
      return
    }

    const onMouseLeave = () => {
      if (openDrawer === true) toggleOpenDrawer()
      return
    }

    const styling = {
      backgroundColor: customizedTheme.backgroundColor
    }

    /*const submenuStyle = {
      backgroundColor: "rgba(0,0,0,0.3)",
      color: customizedTheme.textColor
    }*/

    /*const submenuColor = {
      color: customizedTheme.textColor
    }*/

    return (
      <SidebarWrapper>
        <Layout.Sider
          trigger={null}
          collapsible={true}
          collapsed={collapsed}
          width={240}
          className="isomorphicSidebar"
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          style={styling}>

          <Logo collapsed={collapsed} />

          <Scrollbars style={{ height: height - 70 }}>
            <Menu
              onClick={this.handleClick}
              theme="dark"
              className="isoDashboardMenu"
              mode={mode}
              openKeys={collapsed ? [] : openKeys}
              selectedKeys={current}
              onOpenChange={this.onOpenChange}>

              <Menu.Item key="clients">
                <Link to="/dashboard/clients">
                  <Icon type="contacts" />
                  <span>Clients</span>
                </Link>
              </Menu.Item>

              <Menu.Item key="projects">
                <Link to="/dashboard/projects">
                  <Icon type="heat-map" />
                  <span>Projets</span>
                </Link>
              </Menu.Item>


            </Menu>
          </Scrollbars>
        </Layout.Sider>
      </SidebarWrapper>
    )
  }
}

export default connect(

  // maptStatToProps
  state => ({
    //app: state.App,
    openKeys: state.App.get('openKeys'),
    openDrawer: state.App.get('openDrawer'),
    collapsed: state.App.get('collapsed'),
    height: state.App.get('height'),
    view: state.App.get('view'),
    current: state.App.get('current'),
    customizedTheme: state.Theme.sidebarTheme
  }),

  // mapDispatchToProps
  {
    toggleOpenDrawer: actions.toggleOpenDrawer,
    changeOpenKeys: actions.changeOpenKeys,
    changeCurrent: actions.changeCurrent,
    toggleCollapsed: actions.toggleCollapsed
  }
)(Sidebar)