import React from 'react'
import {Link} from 'react-router-dom'
import {Icon} from 'antd'

import {siteConfig} from '../../settings'

export default ({collapsed}) => {
	return (
		<div className="isoLogoWrapper">
			{collapsed ? (
				<h3>
					<Link to="/dashboard">
						<Icon type="crown" />
					</Link>
				</h3>
			) : (
				<h3>
					<Link to="/dashboard">{siteConfig.siteName}</Link>
				</h3>
			)}
		</div>
	)
}
