const actions = {
	LOAD_CLIENT: 'LOAD_CLIENT',
	LOAD_CLIENT_SUCCESS: 'LOAD_CLIENT_SUCCESS',
	LOAD_CLIENT_ERROR: 'LOAD_CLIENT_ERROR',

	SAVE_CLIENT: 'SAVE_CLIENT',
	SAVE_CLIENT_SUCCESS: 'SAVE_CLIENT_SUCCESS',
	SAVE_CLIENT_ERROR: 'SAVE_CLIENT_ERROR',

	CHANGE_CLIENT: 'CHANGE_CLIENT',

	RESET_CLIENT: 'RESET_CLIENT',

	REMOVE_CLIENT: 'REMOVE_CLIENT',

	// MULTIPLE

	SEARCH_CLIENTS: 'SEARCH_CLIENTS',
	SEARCH_CLIENTS_PARAMS: 'SEARCH_CLIENTS_PARAMS',
	SEARCH_CLIENTS_SUCCESS: 'SEARCH_CLIENTS_SUCCESS',
	SEARCH_CLIENTS_ERROR: 'SEARCH_CLIENTS_ERROR',

	// -- Single

	load: _id => ({
		type: actions.LOAD_CLIENT,
		payload: _id
	}),

	change: (field, value) => ({
		type: actions.CHANGE_CLIENT,
		payload: {field, value}
	}),

	reset: () => ({
		type: actions.RESET_CLIENT
	}),

	save: payload => ({
		type: actions.SAVE_CLIENT,
		payload
	}),

	// -- Multiple

	remove: _id => ({
		type: actions.REMOVE_CLIENT,
		payload: _id
	}),

	search: (params={}) => ({
		type: actions.SEARCH_CLIENTS,
		payload: params
	})

}

export default actions