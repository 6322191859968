import {query} from '../helpers/apollo'
import axios from '../helpers/axios'

export const search = function(params={}){
	//console.log('[API]', 'searchClients', params)

	return query(`
    query($limit:Int, $skip:Int){
      searchClients(limit:$limit, skip:$skip){
        total
        limit
        skip
        data{
          _id
          name
        }
      }
    }`,
		{
			limit: params.limit || 10,
			skip: params.skip || 0
		}
	)
		.then(res => res.data.searchClients)
		.catch(err => console.log('🔥 GRAPHQL', err))

}

export const get = function(_id){
	//console.log('[API]', 'getClient', _id)

	return query(`
		query($_id:String!){
	    getClientById(_id:$_id){
	      _id name
	      
	      projects{
	        _id name
	      }
	    }
    }`,
		{
			_id
		},
	)
	.then(res => res.data.getClientById)
	.catch(err => console.log('🔥 GRAPHQL', err))
}

export const save = function(client){
	//console.log('[API]', 'saveClient', client)

	return (client._id
			? axios.post(`/client/${client._id}`, client)
			: axios.put(`/client`, client)
	)
		.then(res => res.data)

}

export const remove = function(_id){
	//console.log('[API]', 'removeClient', _id)

	return axios.delete(`/client/${_id}`)
		.then(res => res.data)

}
