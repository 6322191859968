import {all, takeEvery, select, put, fork} from 'redux-saga/effects'

import actions from './actions'

import * as api from '../../helpers/api-client'

import {openErrorNotification, openSuccessNotification} from '../../helpers/notification'

// Single

export function* load(){
	yield takeEvery(actions.LOAD_CLIENT, function* ({payload}){
		//console.log('[SAGA]', 'loadClient()', payload)

		try{
			const res = yield api.get(payload)

			if(res.error){
				yield put({type: actions.LOAD_CLIENT_ERROR})
			}else{
				yield put({type: actions.LOAD_CLIENT_SUCCESS, payload: res})
			}

		} catch(err){
			console.log('🔥', err)
			yield put({type: actions.LOAD_CLIENT_ERROR})
		}

	})
}

export function* save(){
	yield takeEvery(actions.SAVE_CLIENT, function* ({payload}){
		//console.log('[SAGA]', 'saveClient()', payload)

		// Consolidate single client object
		if(payload) yield put({type: actions.CHANGE_CLIENT, payload})

		// Get all client data
		let client = yield select(state => state.Client.single)

		try{
			const res = yield api.save(client)

			if(res.error){
				openErrorNotification(res.error)
				yield put({type: actions.SAVE_CLIENT_ERROR})
			}else{
				openSuccessNotification('Client sauvé')
				yield put({type: actions.SAVE_CLIENT_SUCCESS, payload: {isNew: !client._id, ...res}})
				yield put({type: actions.LOAD_CLIENT, payload: res._id})
			}

		} catch(err){
			console.log('🔥', err)
			yield put({type: actions.SAVE_CLIENT_ERROR})
		}

	})
}


// Multiple

export function* remove(){

	yield takeEvery(actions.REMOVE_CLIENT, function* ({payload}) {

		try{
			yield api.remove(payload)
			openSuccessNotification('Client supprimé')
		} catch(e){
		}
	})

}

export function* search(){

	yield takeEvery(actions.SEARCH_CLIENTS, function* (action) {
		const params = action.payload || {}

		if(Object.entries(params).length){
			//console.log('update params with', params)
			yield put({type: actions.SEARCH_CLIENTS_PARAMS, payload: params})
		}

		try{
			const params = yield select(state => state.Client.params)
			//console.log('params used to request clients', params)
			const res = yield api.search(params)

			if(res.error){
				openErrorNotification(res.error)
				yield put({type: actions.SEARCH_CLIENTS_ERROR})
			}else{
				yield put({type: actions.SEARCH_CLIENTS_SUCCESS, payload: res})
			}

		} catch(err){
			console.log('🔥', err)
			yield put({type: actions.SEARCH_CLIENTS_ERROR})
		}

	})
}


export default function* rootSaga(){
	yield all([
		fork(load),
		fork(save),
		fork(remove),

		// Multiple
		fork(search)
	])
}