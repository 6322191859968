import {all, takeEvery, put, fork} from 'redux-saga/effects'
import {push} from 'react-router-redux'

import {getToken, clearToken} from '../../helpers/utility'
import actions from './actions'
import meActions from '../me/actions'
import * as api from '../../helpers/api-auth'
import crypto from '../../helpers/crypto'

import {openErrorNotification, openSuccessNotification} from '../../helpers/notification'

export function* loginRequest() {
	//console.log('loginRequest()')

	yield takeEvery(actions.LOGIN_REQUEST, function* ({payload}) {
		const {login, password} = payload

		try {
			const res = yield api.login(login, password)
			console.log('login res => ', login, password, res)

			if (res.success) {
				yield put({type: actions.LOGIN_SUCCESS, token: res.user.auth})
				yield loadProfile(res.user.auth)
			} else {
				openErrorNotification('Mauvais identifiant ou mot de passe')
				yield put({type: actions.LOGIN_ERROR})
			}

		} catch (e) {
			yield put({type: actions.LOGIN_ERROR})
		}

	})
}

export function* lostRequest() {
	yield takeEvery('LOST_REQUEST', function* ({payload}) {
		const {login} = payload

		try {
			const res = yield api.lost(login)
			if(res.success) {
				openSuccessNotification('Procédure envoyée par email')
				yield put({type: actions.LOST_SUCCESS})
			}else {
				openErrorNotification(res.error || 'Erreur, utilisateur inconnu')
				yield put({type: actions.LOST_ERROR})
			}

		} catch (e) {
			yield put({type: actions.LOST_ERROR})
		}

	})
}

export function* resetRequest() {
	yield takeEvery('RESET_REQUEST', function* ({payload}) {
		const {token, password} = payload

		try {
			const res = yield api.reset(token, password)
			if(res.success){
				openSuccessNotification('Changement de mot de passe confirmé')
				yield put({type: actions.RESET_SUCCESS})
			}else {
				openErrorNotification(res.error || 'Impossible de changer le mot de passe')
				yield put({type: actions.RESET_ERROR})
			}
		} catch (e) {
			yield put({type: actions.RESET_ERROR})
		}

	})
}

export function* loginSuccess() {
	yield takeEvery(actions.LOGIN_SUCCESS, function* (payload) {
		//console.log('login success')
		yield localStorage.setItem('kapp_token', payload.token)
	})
}

export function* loginError() {
	yield takeEvery(actions.LOGIN_ERROR, function* () {
	})
}

export function* logout() {
	yield takeEvery(actions.LOGOUT, function* () {
		clearToken()
		localStorage.removeItem('kapp_token')
		localStorage.removeItem('kappuccino')
		yield put(push('/'))
	})
}

export function* checkAuthorization() {
	yield takeEvery(actions.CHECK_AUTHORIZATION, function* () {
		const token = getToken().get('idToken')

		//console.log('saga CHECK_AUTHORIZATION', 'token=', token)

		if (token) {
			yield put({type: actions.LOGIN_SUCCESS, token})
			// Load the profile (if the token is good)
			yield loadProfile(token)
		}

	})
}

function* loadProfile(token) {
	//console.log('🌷🌷 loadProfile()', token)

	const res = yield api.auth(token)
	//console.log('🍕🍕 profile loaded', res)

	if (res.auth === token) {
		//console.log('🦄 Token matches Profile.auth => good to go')

		const hashed = crypto.encrypt(JSON.stringify(res))

		yield localStorage.setItem('kappuccino', hashed)

		yield put({type: meActions.MYPROFILE_LOADED, payload: res})
	} else {
		console.log('🤔 Token ≠ Profile.auth')
		yield put({type: actions.LOGOUT})
	}

}

export default function* rootSaga() {
	yield all([
		fork(checkAuthorization),
		fork(loginRequest),
		fork(lostRequest),
		fork(resetRequest),
		fork(loginSuccess),
		fork(loginError),
		fork(logout)
	])
}
