import {query} from '../helpers/apollo'
import axios from '../helpers/axios'

export const search = function(params={}){
	//console.log('[API]', 'searchProjects', params)

	return query(`
    query($limit:Int, $skip:Int){
      searchProjects(limit:$limit, skip:$skip){
        total limit skip

        data{
          _id name
          
          _client
          client{
            name
          }
        }

      }
    }`,
		{
			limit: params.limit || 10,
			skip: params.skip || 0
		}
	)
		.then(res => res.data.searchProjects)
		.catch(err => console.log('🔥 GRAPHQL', err))

}

export const get = function(_id){
	//console.log('[API]', 'getProject', _id)

	return query(`
		query($_id:String!){
	    getProjectById(_id:$_id){
	      _id name tags _client comment emails phones
	      
	      items{
	        _id type name comment alert cost price
	      }
	     	      
	    }
    }`,
		{
			_id
		},
	)
	.then(res => res.data.getProjectById)
	.catch(err => console.log('🔥 GRAPHQL', err))
}

export const save = function(project){
	//console.log('[API]', 'saveProject', project)

	return (project._id
			? axios.post(`/project/${project._id}`, project)
			: axios.put(`/project`, project)
	)
		.then(res => res.data)

}

export const remove = function(_id){
	//console.log('[API]', 'removeProject', _id)

	return axios.delete(`/project/${_id}`)
		.then(res => res.data)

}

//-- Item

export const addItem = function(_project, item){
	console.log('[API]', 'addProjectItem', _project, item)

	return axios.put(`/project/${_project}/item`, item)
		.then(res => res.data)

}

export const updateItem = function(_project, _item, item){
	console.log('[API]', 'updateProjectItem', _project, _item, item)

	return axios.post(`/project/${_project}/item/${_item}`, item)
		.then(res => res.data)

}

export const removeItem = function(_project, _item){
	console.log('[API]', 'removeProjectItem', _project, _item)

	return axios.delete(`/project/${_project}/item/${_item}`)
		.then(res => res.data)

}