import React from 'react'
import styled from 'styled-components'

const Wrapper = styled.div`
  background: #364d79;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  overflow: hidden;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  box-shadow: 0 0 1px 0px rgba(0, 0, 0, .4);
  position: relative;
`

const Initial = styled.div`
	text-transform: uppercase;
	font-size: 13px;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	color: #FFF;
`

export default ({profile}) => {

	const avatar = profile.photo_url || ''

	const firstName = profile.firstName || ''
	const lastName = profile.lastName || ''
	const initial = avatar ? null : `${firstName.substr(0, 1)}${lastName.substr(0, 1)}`

	return (
		<Wrapper style={{backgroundImage: `url(${avatar})`}}>
			{ initial && <Initial>{initial}</Initial> }
		</Wrapper>
	)
}