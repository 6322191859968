import React from 'react'
import {Provider} from 'react-redux'
import {store, history} from './redux/store'
import {ThemeProvider} from 'styled-components'

import PublicRoutes from './router'
import themes from './settings/themes'
import {themeConfig} from './settings'
import DashAppHolder from './dashApp.style'
import Boot from './redux/boot'
import packageJson from "../package.json"

//componentDidMount() {
	document.title = `Kappuccino (${packageJson.version})`
//}

const DashApp = () => (
	<ThemeProvider theme={themes[themeConfig.theme]}>
		<DashAppHolder>
			<Provider store={store}>
				<PublicRoutes history={history}/>
			</Provider>
		</DashAppHolder>
	</ThemeProvider>
)

Boot()
	.then(() => DashApp())
	.catch(error => console.error(error))

export default DashApp