import React, { Component } from 'react'
import { Switch, Route } from 'react-router-dom'

import asyncComponent from '../../helpers/AsyncFunc'


class AppRouter extends Component {

  render() {
    const {url} = this.props //style

    return (
      <Switch>
        <Route exact path={`${url}/`} component={asyncComponent(() => import('../Alert/Alerts'))}/>

        <Route exact path={`${url}/clients`} component={asyncComponent(() => import('../Client/Clients'))}/>
        <Route exact path={`${url}/client/:_id`} component={asyncComponent(() => import('../Client/Client'))}/>

        <Route exact path={`${url}/projects`} component={asyncComponent(() => import('../Project/Projects'))}/>
        <Route exact path={`${url}/project/:_id`} component={asyncComponent(() => import('../Project/Project'))}/>

        {/*<Route exact path={`${url}/alerts`} component={asyncComponent(() => import('../Alert/Alerts'))}/>*/}
      </Switch>
    )

  }
}

export default AppRouter