const actions = {
	LOAD_PROJECT: 'LOAD_PROJECT',
	LOAD_PROJECT_SUCCESS: 'LOAD_PROJECT_SUCCESS',
	LOAD_PROJECT_ERROR: 'LOAD_PROJECT_ERROR',

	SAVE_PROJECT: 'SAVE_PROJECT',
	SAVE_PROJECT_ERROR: 'SAVE_PROJECT_ERROR',

	CHANGE_PROJECT: 'CHANGE_PROJECT',

	RESET_PROJECT: 'RESET_PROJECT',

	REMOVE_PROJECT: 'REMOVE_PROJECT',

	// MULTIPLE

	SEARCH_PROJECTS: 'SEARCH_PROJECTS',
	SEARCH_PROJECTS_PARAMS: 'SEARCH_PROJECTS_PARAMS',
	SEARCH_PROJECTS_SUCCESS: 'SEARCH_PROJECTS_SUCCESS',
	SEARCH_PROJECTS_ERROR: 'SEARCH_PROJECTS_ERROR',

	// ITEMS
	SAVE_PROJECT_ITEM: 'SAVE_PROJECT_ITEM',
	SAVE_PROJECT_ITEM_SUCCESS: 'SAVE_PROJECT_ITEM_SUCCESS',
	SAVE_PROJECT_ITEM_ERROR: 'SAVE_PROJECT_ITEM_ERROR',

	REMOVE_PROJECT_ITEM: 'REMOVE_PROJECT_ITEM',
	REMOVE_PROJECT_ITEM_ERROR: 'REMOVE_PROJECT_ITEM_ERROR',

	// -- Single

	load: _id => ({
		type: actions.LOAD_PROJECT,
		payload: _id
	}),

	change: (field, value) => ({
		type: actions.CHANGE_PROJECT,
		payload: {field, value}
	}),

	reset: () => ({
		type: actions.RESET_PROJECT
	}),

	save: payload => ({
		type: actions.SAVE_PROJECT,
		payload
	}),

	// -- Multiple

	remove: _id => ({
		type: actions.REMOVE_PROJECT,
		payload: _id
	}),

	search: (params={}) => ({
		type: actions.SEARCH_PROJECTS,
		payload: params
	}),

	// -- Items

	saveItem: item => ({
		type: actions.SAVE_PROJECT_ITEM,
		payload: item
	}),

	removeItem: (_id, _item) => ({
		type: actions.REMOVE_PROJECT_ITEM,
		payload: {_id, _item}
	})

}

export default actions