const actions = {
	MYPROFILE_LOADED: 'MYPROFILE_LOADED',
	MYPROFILE_UNLOAD: 'MYPROFILE_UNLOAD',

	CHANGE_MYPROFILE: 'CHANGE_MYPROFILE',

	SAVE_MYPROFILE: 'SAVE_MYPROFILE',
	SAVE_MYPROFILE_SUCCESS: 'SAVE_MYPROFILE_SUCCESS',
	SAVE_MYPROFILE_ERROR: 'SAVE_MYPROFILE_ERROR',

	UPLOAD_MYPROFILE_AVATAR: 'UPLOAD_MYPROFILE_AVATAR',
	UPLOAD_MYPROFILE_AVATAR_SUCCESS: 'UPLOAD_MYPROFILE_AVATAR_SUCCESS',
	UPLOAD_MYPROFILE_AVATAR_ERROR: 'UPLOAD_MYPROFILE_AVATAR_ERROR',

	//--

	changeMyProfile : (field, value) => ({
		type: actions.CHANGE_MYPROFILE,
		payload: {field, value}
	}),

	saveMyProfile: () => ({
		type: actions.SAVE_MYPROFILE
	}),

	uploadMyProfileAvatar: (params, cb) => ({
		type: actions.UPLOAD_MYPROFILE_AVATAR,
		payload: {...params, cb}
	})

}

export default actions