import {all, takeEvery, select, put, fork} from 'redux-saga/effects'

import actions from './actions'

import * as api from '../../helpers/api-project'

import {openErrorNotification, openSuccessNotification} from '../../helpers/notification'

// Single

export function* load(){
	yield takeEvery(actions.LOAD_PROJECT, function* ({payload}){
		//console.log('[SAGA]', 'loadProject()', payload)

		try{
			const res = yield api.get(payload)

			if(res.error){
				yield put({type: actions.LOAD_PROJECT_ERROR})
			}else{
				yield put({type: actions.LOAD_PROJECT_SUCCESS, payload: res})
			}

		} catch(err){
			console.log('🔥', err)
			yield put({type: actions.LOAD_PROJECT_ERROR})
		}

	})
}

export function* save(){
	yield takeEvery(actions.SAVE_PROJECT, function* ({payload}){
		//console.log('[SAGA]', 'saveProject()', payload)

		// Consolidate single project object
		if(payload) yield put({type: actions.CHANGE_PROJECT, payload})

		// Get all project data
		let project = yield select(state => state.Project.single)

		try{
			const res = yield api.save(project)

			if(res.error){
				openErrorNotification(res.error)
				yield put({type: actions.SAVE_PROJECT_ERROR})
			}else{
				openSuccessNotification('Projet sauvé')
				yield put({type: actions.LOAD_PROJECT, payload: res._id})
			}

		} catch(err){
			console.log('🔥', err)
			yield put({type: actions.SAVE_PROJECT_ERROR})
		}

	})
}


// Multiple

export function* remove(){

	yield takeEvery(actions.REMOVE_PROJECT, function* ({payload}) {

		try{
			yield api.remove(payload)
			openSuccessNotification('Projet supprimé')
		} catch(e){
		}
	})

}

export function* search(){

	yield takeEvery(actions.SEARCH_PROJECTS, function* (action) {
		const params = action.payload || {}

		if(Object.entries(params).length){
			//console.log('update params with', params)
			yield put({type: actions.SEARCH_PROJECTS_PARAMS, payload: params})
		}

		try{
			const params = yield select(state => state.Project.params)
			//console.log('params used to request projects', params)
			const res = yield api.search(params)

			if(res.error){
				openErrorNotification(res.error)
				yield put({type: actions.SEARCH_PROJECTS_ERROR})
			}else{
				yield put({type: actions.SEARCH_PROJECTS_SUCCESS, payload: res})
			}

		} catch(err){
			console.log('🔥', err)
			yield put({type: actions.SEARCH_PROJECTS_ERROR})
		}

	})
}


// Item

export function* removeItem(){
	yield takeEvery(actions.REMOVE_PROJECT_ITEM, function* ({payload}){
		//console.log('[SAGA]', 'removeItem()', payload)

		let _project = yield select(state => state.Project.single._id)

		try{
			const res = yield api.removeItem(_project, payload._item)

			if(res.error){
				openErrorNotification(res.error)
				yield put({type: actions.REMOVE_PROJECT_ITEM_ERROR})
			}

		} catch(err){
			console.log('🔥', err)
			yield put({type: actions.REMOVE_PROJECT_ITEM_ERROR})
		}

	})
}

export function* saveItems(){
	yield takeEvery(actions.SAVE_PROJECT_ITEM, function* ({payload}){
		//console.log('[SAGA]', 'saveProject()', payload)

		if(payload._id) return yield put({type: actions.SAVE_PROJECT_ITEM_SUCCESS, payload})

		let _project = yield select(state => state.Project.single._id)

		try{
			const res = yield api.addItem(_project, payload)

			if(res.error){
				openErrorNotification(res.error)
				yield put({type: actions.SAVE_PROJECT_ITEM_ERROR})
			}else{
				openSuccessNotification('Item ajouté au projet')
				yield put({type: actions.SAVE_PROJECT_ITEM_SUCCESS, payload: res})
			}

		} catch(err){
			console.log('🔥', err)
			yield put({type: actions.SAVE_PROJECT_ERROR})
		}

	})
}


export default function* rootSaga(){
	yield all([
		fork(load),
		fork(save),
		fork(remove),

		// Multiple
		fork(search),

		// Items
		fork(saveItems),
		fork(removeItem)
	])
}
