import actions from './actions'

const initState = {
	loaded: false,
	single: {},
	total: 0,
	params: {
		limit: 999,
		skip: 0,
	},
	data: []
}

export default function projectReducer(state = initState, action) {

	const {payload} = action

	switch (action.type) {

		case actions.LOAD_PROJECT_SUCCESS:
			return {
				...payload,
				single: payload
			}

		case actions.CHANGE_PROJECT:
			return {
				...state,
				single: {
					...state.single,
					...payload
				}
			}

		case actions.RESET_PROJECT:
			return {
				...state,
				single: {}
			}


		// MULTIPLE

		case actions.REMOVE_PROJECT:
			return {
				...state,
				data: state.data.filter(d => d._id !== payload)
			}

		case actions.SEARCH_PROJECTS_PARAMS:
			return {
				...state,
				params: {
					...state.params,
					...payload
				}
			}

		case actions.SEARCH_PROJECTS_SUCCESS:
			return {
				...state,
				total: payload.total,
				data: payload.data
			}


		// ITEMS

		case actions.SAVE_PROJECT_ITEM_SUCCESS:
			let nextItems = state.single.items || []
			let exists = nextItems.findIndex(i => i._id === payload._id) > -1

			nextItems = exists
				? nextItems.map(i => i._id === payload._id ? payload : i)
				: [...nextItems, payload]

			return {
				...state,
				single: {
					...state.single,
					items: nextItems
				}
			}

		case actions.REMOVE_PROJECT_ITEM:
			return {
				...state,
				single: {
					...state.single,
					items: state.single.items.filter(i => i._id !== payload._item)
				}
			}

		default:
			return state
	}
}
